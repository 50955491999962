
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as _91id_93qKklDT0YZ0Meta } from "/opt/build/repo/pages/adp/[id].vue?macro=true";
import { default as _91id_93CNnj8RgHOzMeta } from "/opt/build/repo/pages/adp/companion/[id].vue?macro=true";
import { default as indexVHIuOwRm5dMeta } from "/opt/build/repo/pages/adp/companion/index.vue?macro=true";
import { default as compareuukRyxi5pkMeta } from "/opt/build/repo/pages/adp/compare.vue?macro=true";
import { default as compareqcZTsmukfAMeta } from "/opt/build/repo/pages/adp/diff/compare.vue?macro=true";
import { default as indexT4oEBJmEA3Meta } from "/opt/build/repo/pages/adp/diff/index.vue?macro=true";
import { default as drafttJ5vwVeUI8Meta } from "/opt/build/repo/pages/adp/draft.vue?macro=true";
import { default as hdraftoPsdrZBuQUMeta } from "/opt/build/repo/pages/adp/hdraft.vue?macro=true";
import { default as indexBBw6ajJ4OyMeta } from "/opt/build/repo/pages/adp/index.vue?macro=true";
import { default as rookie_heatmapWYIVmdoY6xMeta } from "/opt/build/repo/pages/adp/rookie_heatmap.vue?macro=true";
import { default as sfb_rostersZynSuiHJQYMeta } from "/opt/build/repo/pages/adp/sfb_rosters.vue?macro=true";
import { default as sfbZk5tzIcbtbMeta } from "/opt/build/repo/pages/adp/sfb.vue?macro=true";
import { default as adppKznPT3kQ2Meta } from "/opt/build/repo/pages/bestball/draftkings/adp.vue?macro=true";
import { default as indexF2bYu51dDsMeta } from "/opt/build/repo/pages/bestball/index.vue?macro=true";
import { default as adpUQLblmmIkUMeta } from "/opt/build/repo/pages/bestball/underdog/adp.vue?macro=true";
import { default as rosterGridqIrLPIMfKnMeta } from "/opt/build/repo/pages/bestball/underdog/rosterGrid.vue?macro=true";
import { default as indexTzhEzmF5VcMeta } from "/opt/build/repo/pages/bestball/underdog/rostership/index.vue?macro=true";
import { default as stackspZ2hUq6PTEMeta } from "/opt/build/repo/pages/bestball/underdog/rostership/stacks.vue?macro=true";
import { default as useryjghWfxWHdMeta } from "/opt/build/repo/pages/bestball/underdog/rostership/user.vue?macro=true";
import { default as _91_46_46_46slug_93mvebfMgEVhMeta } from "/opt/build/repo/pages/blog/[...slug].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as confirm487kH8ZehdMeta } from "/opt/build/repo/pages/confirm.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as playerlistnTtPJ8AIcXMeta } from "/opt/build/repo/pages/playerlist.vue?macro=true";
import { default as _91id_93BDZ85NG2ZEMeta } from "/opt/build/repo/pages/rankings/edit/[id].vue?macro=true";
import { default as indexdiPdBHVZXMMeta } from "/opt/build/repo/pages/rankings/edit/index.vue?macro=true";
import { default as indexjbjEuABWP2Meta } from "/opt/build/repo/pages/rankings/index.vue?macro=true";
import { default as _91type_93ZZpUC6zLTyMeta } from "/opt/build/repo/pages/rankings/new/[type].vue?macro=true";
import { default as indexaoGiO8iJRYMeta } from "/opt/build/repo/pages/rankings/new/index.vue?macro=true";
import { default as _91id_93qBGZ6JXBWqMeta } from "/opt/build/repo/pages/rankings/view/[id].vue?macro=true";
import { default as index062flViEEWMeta } from "/opt/build/repo/pages/rankings/view/index.vue?macro=true";
import { default as releasesPJ6qu324atMeta } from "/opt/build/repo/pages/releases.vue?macro=true";
import { default as gamesTGTS1N7Kv4Meta } from "/opt/build/repo/pages/rosters/games.vue?macro=true";
import { default as indexstVqp0MGTYMeta } from "/opt/build/repo/pages/rosters/index.vue?macro=true";
import { default as lineupsj0BX5cgki3Meta } from "/opt/build/repo/pages/rosters/lineups.vue?macro=true";
import { default as playersRZlccIi1NlMeta } from "/opt/build/repo/pages/rosters/players.vue?macro=true";
import { default as bye8qMsyIKyRgMeta } from "/opt/build/repo/pages/rosters/suggestions/bye.vue?macro=true";
import { default as flexBhNZMWkvM7Meta } from "/opt/build/repo/pages/rosters/suggestions/flex.vue?macro=true";
import { default as injuryyc6Ygm8Q59Meta } from "/opt/build/repo/pages/rosters/suggestions/injury.vue?macro=true";
import { default as startOn2VRNtR7sMeta } from "/opt/build/repo/pages/rosters/suggestions/start.vue?macro=true";
import { default as testdNWvb1891uMeta } from "/opt/build/repo/pages/rosters/test.vue?macro=true";
import { default as ownershipiDbExXLbpwMeta } from "/opt/build/repo/pages/rosters/waivers/ownership.vue?macro=true";
import { default as trending9dJsLGwdntMeta } from "/opt/build/repo/pages/rosters/waivers/trending.vue?macro=true";
import { default as indexbZMtPFDIBGMeta } from "/opt/build/repo/pages/stats/index.vue?macro=true";
import { default as playerxNQ6UjbfQ8Meta } from "/opt/build/repo/pages/stats/player.vue?macro=true";
import { default as rookieaHJww6AfBOMeta } from "/opt/build/repo/pages/stats/rookie.vue?macro=true";
import { default as teamWqanleOhRWMeta } from "/opt/build/repo/pages/stats/team.vue?macro=true";
import { default as cancelgMtVBL2LW0Meta } from "/opt/build/repo/pages/subscription/cancel.vue?macro=true";
import { default as index82ngKLZ0r1Meta } from "/opt/build/repo/pages/subscription/index.vue?macro=true";
import { default as successKxHv2k405OMeta } from "/opt/build/repo/pages/subscription/success.vue?macro=true";
import { default as playerm44yvtpzT5Meta } from "/opt/build/repo/pages/trades/player.vue?macro=true";
import { default as indexgyqAU6b3CDMeta } from "/opt/build/repo/pages/trades/rostership/index.vue?macro=true";
import { default as useriBFoNqb9hBMeta } from "/opt/build/repo/pages/trades/rostership/user.vue?macro=true";
import { default as userZno5qXDIzQMeta } from "/opt/build/repo/pages/trades/user.vue?macro=true";
import { default as unauthorizedBrmPFsPVpmMeta } from "/opt/build/repo/pages/unauthorized.vue?macro=true";
import { default as _91type_93ubPWCY4aoSMeta } from "/opt/build/repo/pages/user/[type].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "adp-id",
    path: "/adp/:id()",
    meta: _91id_93qKklDT0YZ0Meta || {},
    component: () => import("/opt/build/repo/pages/adp/[id].vue")
  },
  {
    name: "adp-companion-id",
    path: "/adp/companion/:id()",
    meta: _91id_93CNnj8RgHOzMeta || {},
    component: () => import("/opt/build/repo/pages/adp/companion/[id].vue")
  },
  {
    name: "adp-companion",
    path: "/adp/companion",
    component: () => import("/opt/build/repo/pages/adp/companion/index.vue")
  },
  {
    name: "adp-compare",
    path: "/adp/compare",
    component: () => import("/opt/build/repo/pages/adp/compare.vue")
  },
  {
    name: "adp-diff-compare",
    path: "/adp/diff/compare",
    component: () => import("/opt/build/repo/pages/adp/diff/compare.vue")
  },
  {
    name: "adp-diff",
    path: "/adp/diff",
    component: () => import("/opt/build/repo/pages/adp/diff/index.vue")
  },
  {
    name: "adp-draft",
    path: "/adp/draft",
    meta: drafttJ5vwVeUI8Meta || {},
    component: () => import("/opt/build/repo/pages/adp/draft.vue")
  },
  {
    name: "adp-hdraft",
    path: "/adp/hdraft",
    meta: hdraftoPsdrZBuQUMeta || {},
    component: () => import("/opt/build/repo/pages/adp/hdraft.vue")
  },
  {
    name: "adp",
    path: "/adp",
    component: () => import("/opt/build/repo/pages/adp/index.vue")
  },
  {
    name: "adp-rookie_heatmap",
    path: "/adp/rookie_heatmap",
    component: () => import("/opt/build/repo/pages/adp/rookie_heatmap.vue")
  },
  {
    name: "adp-sfb_rosters",
    path: "/adp/sfb_rosters",
    component: () => import("/opt/build/repo/pages/adp/sfb_rosters.vue")
  },
  {
    name: "adp-sfb",
    path: "/adp/sfb",
    meta: sfbZk5tzIcbtbMeta || {},
    component: () => import("/opt/build/repo/pages/adp/sfb.vue")
  },
  {
    name: "bestball-draftkings-adp",
    path: "/bestball/draftkings/adp",
    meta: adppKznPT3kQ2Meta || {},
    component: () => import("/opt/build/repo/pages/bestball/draftkings/adp.vue")
  },
  {
    name: "bestball",
    path: "/bestball",
    component: () => import("/opt/build/repo/pages/bestball/index.vue")
  },
  {
    name: "bestball-underdog-adp",
    path: "/bestball/underdog/adp",
    meta: adpUQLblmmIkUMeta || {},
    component: () => import("/opt/build/repo/pages/bestball/underdog/adp.vue")
  },
  {
    name: "bestball-underdog-rosterGrid",
    path: "/bestball/underdog/rosterGrid",
    meta: rosterGridqIrLPIMfKnMeta || {},
    component: () => import("/opt/build/repo/pages/bestball/underdog/rosterGrid.vue")
  },
  {
    name: "bestball-underdog-rostership",
    path: "/bestball/underdog/rostership",
    component: () => import("/opt/build/repo/pages/bestball/underdog/rostership/index.vue")
  },
  {
    name: "bestball-underdog-rostership-stacks",
    path: "/bestball/underdog/rostership/stacks",
    meta: stackspZ2hUq6PTEMeta || {},
    component: () => import("/opt/build/repo/pages/bestball/underdog/rostership/stacks.vue")
  },
  {
    name: "bestball-underdog-rostership-user",
    path: "/bestball/underdog/rostership/user",
    meta: useryjghWfxWHdMeta || {},
    component: () => import("/opt/build/repo/pages/bestball/underdog/rostership/user.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93mvebfMgEVhMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/opt/build/repo/pages/confirm.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "playerlist",
    path: "/playerlist",
    component: () => import("/opt/build/repo/pages/playerlist.vue")
  },
  {
    name: "rankings-edit-id",
    path: "/rankings/edit/:id()",
    meta: _91id_93BDZ85NG2ZEMeta || {},
    component: () => import("/opt/build/repo/pages/rankings/edit/[id].vue")
  },
  {
    name: "rankings-edit",
    path: "/rankings/edit",
    meta: indexdiPdBHVZXMMeta || {},
    redirect: indexdiPdBHVZXMMeta?.redirect,
    component: () => import("/opt/build/repo/pages/rankings/edit/index.vue")
  },
  {
    name: "rankings",
    path: "/rankings",
    component: () => import("/opt/build/repo/pages/rankings/index.vue")
  },
  {
    name: "rankings-new-type",
    path: "/rankings/new/:type()",
    meta: _91type_93ZZpUC6zLTyMeta || {},
    component: () => import("/opt/build/repo/pages/rankings/new/[type].vue")
  },
  {
    name: "rankings-new",
    path: "/rankings/new",
    meta: indexaoGiO8iJRYMeta || {},
    redirect: indexaoGiO8iJRYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/rankings/new/index.vue")
  },
  {
    name: "rankings-view-id",
    path: "/rankings/view/:id()",
    meta: _91id_93qBGZ6JXBWqMeta || {},
    component: () => import("/opt/build/repo/pages/rankings/view/[id].vue")
  },
  {
    name: "rankings-view",
    path: "/rankings/view",
    component: () => import("/opt/build/repo/pages/rankings/view/index.vue")
  },
  {
    name: "releases",
    path: "/releases",
    component: () => import("/opt/build/repo/pages/releases.vue")
  },
  {
    name: "rosters-games",
    path: "/rosters/games",
    meta: gamesTGTS1N7Kv4Meta || {},
    component: () => import("/opt/build/repo/pages/rosters/games.vue")
  },
  {
    name: "rosters",
    path: "/rosters",
    meta: indexstVqp0MGTYMeta || {},
    component: () => import("/opt/build/repo/pages/rosters/index.vue")
  },
  {
    name: "rosters-lineups",
    path: "/rosters/lineups",
    meta: lineupsj0BX5cgki3Meta || {},
    component: () => import("/opt/build/repo/pages/rosters/lineups.vue")
  },
  {
    name: "rosters-players",
    path: "/rosters/players",
    meta: playersRZlccIi1NlMeta || {},
    component: () => import("/opt/build/repo/pages/rosters/players.vue")
  },
  {
    name: "rosters-suggestions-bye",
    path: "/rosters/suggestions/bye",
    meta: bye8qMsyIKyRgMeta || {},
    component: () => import("/opt/build/repo/pages/rosters/suggestions/bye.vue")
  },
  {
    name: "rosters-suggestions-flex",
    path: "/rosters/suggestions/flex",
    meta: flexBhNZMWkvM7Meta || {},
    component: () => import("/opt/build/repo/pages/rosters/suggestions/flex.vue")
  },
  {
    name: "rosters-suggestions-injury",
    path: "/rosters/suggestions/injury",
    meta: injuryyc6Ygm8Q59Meta || {},
    component: () => import("/opt/build/repo/pages/rosters/suggestions/injury.vue")
  },
  {
    name: "rosters-suggestions-start",
    path: "/rosters/suggestions/start",
    meta: startOn2VRNtR7sMeta || {},
    component: () => import("/opt/build/repo/pages/rosters/suggestions/start.vue")
  },
  {
    name: "rosters-test",
    path: "/rosters/test",
    meta: testdNWvb1891uMeta || {},
    component: () => import("/opt/build/repo/pages/rosters/test.vue")
  },
  {
    name: "rosters-waivers-ownership",
    path: "/rosters/waivers/ownership",
    meta: ownershipiDbExXLbpwMeta || {},
    component: () => import("/opt/build/repo/pages/rosters/waivers/ownership.vue")
  },
  {
    name: "rosters-waivers-trending",
    path: "/rosters/waivers/trending",
    meta: trending9dJsLGwdntMeta || {},
    component: () => import("/opt/build/repo/pages/rosters/waivers/trending.vue")
  },
  {
    name: "stats",
    path: "/stats",
    meta: indexbZMtPFDIBGMeta || {},
    redirect: indexbZMtPFDIBGMeta?.redirect,
    component: () => import("/opt/build/repo/pages/stats/index.vue")
  },
  {
    name: "stats-player",
    path: "/stats/player",
    component: () => import("/opt/build/repo/pages/stats/player.vue")
  },
  {
    name: "stats-rookie",
    path: "/stats/rookie",
    component: () => import("/opt/build/repo/pages/stats/rookie.vue")
  },
  {
    name: "stats-team",
    path: "/stats/team",
    component: () => import("/opt/build/repo/pages/stats/team.vue")
  },
  {
    name: "subscription-cancel",
    path: "/subscription/cancel",
    component: () => import("/opt/build/repo/pages/subscription/cancel.vue")
  },
  {
    name: "subscription",
    path: "/subscription",
    component: () => import("/opt/build/repo/pages/subscription/index.vue")
  },
  {
    name: "subscription-success",
    path: "/subscription/success",
    component: () => import("/opt/build/repo/pages/subscription/success.vue")
  },
  {
    name: "trades-player",
    path: "/trades/player",
    meta: playerm44yvtpzT5Meta || {},
    component: () => import("/opt/build/repo/pages/trades/player.vue")
  },
  {
    name: "trades-rostership",
    path: "/trades/rostership",
    component: () => import("/opt/build/repo/pages/trades/rostership/index.vue")
  },
  {
    name: "trades-rostership-user",
    path: "/trades/rostership/user",
    meta: useriBFoNqb9hBMeta || {},
    component: () => import("/opt/build/repo/pages/trades/rostership/user.vue")
  },
  {
    name: "trades-user",
    path: "/trades/user",
    meta: userZno5qXDIzQMeta || {},
    component: () => import("/opt/build/repo/pages/trades/user.vue")
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    component: () => import("/opt/build/repo/pages/unauthorized.vue")
  },
  {
    name: "user-type",
    path: "/user/:type()",
    meta: _91type_93ubPWCY4aoSMeta || {},
    component: () => import("/opt/build/repo/pages/user/[type].vue")
  }
]