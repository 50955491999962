import routerOptions0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.31.0_typescript_yvo5z3jrdtm57b47bilwpjub6e/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}