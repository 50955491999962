import payload_plugin_dHWDzdkIDW from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_oyivjPjBVF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xDsFCjzvkk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rPjPBkGC94 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ic7CEoYAYi from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_ruwkkwqqo3k6p4wwqxtzqoebqu/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import supabase_client_w5AKLAmZH0 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_sSlrcbS7YU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zfzFtDTVm6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_MMBtXf5uc0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WIO5csPo3e from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_3hpFpieE8N from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.2_@libsql+client@0.14.0_@parcel+watcher@2.5.0_@types+node@22.10.7_better-sqlite3@11_qu2imdgibjrgx66p3nh5gxnbw4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_oKSXjvao3w from "/opt/build/repo/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import preview_client_vuxqzlJfuf from "/opt/build/repo/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import plugin_client_mORmjjYNgj from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/.nuxt/floating-vue.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_eMzHGtWvBe from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2__cxv3zcg3frnxok6yeiql4usj5m/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import auth_redirect_HwZxue2pHj from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import slideovers_bHIQJTszKD from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.31.0_typescript@5._fuxomzkqyeesa6q3njt4xrfqn4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_1DKSNeqDYR from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.31.0_typescript@5._fuxomzkqyeesa6q3njt4xrfqn4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_KXqvTPonDq from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.31.0_typescript@5._fuxomzkqyeesa6q3njt4xrfqn4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_biaJZEQhc6 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_QAj87FuOG2 from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_sass_2akcfta2rfm4nyo2ktmxktfoby/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_ruM5fHbijL from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.31.0_typescript_yvo5z3jrdtm57b47bilwpjub6e/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import plugin_SL3zYW8u70 from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescri_dwrrpfuilsuioceyijreslliqe/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import presets_oBVzyKKZpG from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.31.0_typescript_yvo5z3jrdtm57b47bilwpjub6e/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_AB0f7LU0j6 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_rollup@4.31.0_typescript_yvo5z3jrdtm57b47bilwpjub6e/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import floating_zzW5zaa1LU from "/opt/build/repo/plugins/floating.ts";
import sentry_3AyO8nEfhE from "/opt/build/repo/plugins/sentry.ts";
import variables_M3EiEc4WpY from "/opt/build/repo/plugins/variables.ts";
import plugin_auto_pageviews_client_jaHIsWWJcT from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_oyivjPjBVF,
  unhead_xDsFCjzvkk,
  router_rPjPBkGC94,
  _0_siteConfig_ic7CEoYAYi,
  supabase_client_w5AKLAmZH0,
  payload_client_sSlrcbS7YU,
  navigation_repaint_client_zfzFtDTVm6,
  check_outdated_build_client_MMBtXf5uc0,
  chunk_reload_client_WIO5csPo3e,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3hpFpieE8N,
  motion_oKSXjvao3w,
  preview_client_vuxqzlJfuf,
  plugin_client_mORmjjYNgj,
  floating_vue_EIcJ7xiw0h,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_eMzHGtWvBe,
  auth_redirect_HwZxue2pHj,
  slideovers_bHIQJTszKD,
  modals_1DKSNeqDYR,
  colors_KXqvTPonDq,
  plugin_client_biaJZEQhc6,
  plugin_QAj87FuOG2,
  scrollbars_client_ruM5fHbijL,
  plugin_SL3zYW8u70,
  presets_oBVzyKKZpG,
  variables_AB0f7LU0j6,
  floating_zzW5zaa1LU,
  sentry_3AyO8nEfhE,
  variables_M3EiEc4WpY,
  plugin_auto_pageviews_client_jaHIsWWJcT
]